<template>
  <v-container>
    <v-dialog v-model="dialog" persistent max-width="400px" class="elevation-0">
      <v-card>
        <v-card-title>
          <v-icon left>
            {{ icons.mdiLock }}
          </v-icon>
          <span class="text-h5">{{ $t('sifremiDegistir') }}</span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <validation-observer ref="observer">
            <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Eski Şifre" rules="required">
              <v-text-field
                v-model="oldPassword"
                :label="$t('eskiSifre')"
                filled
                :error-messages="errors"
                rounded
                type="password"
                class="rounded"
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              ref="password"
              tag="div"
              mode="eager"
              name="Yeni Şifre"
              rules="required"
              vid="confirmation"
            >
              <v-text-field
                v-model="newPassword"
                :label="$t('yeniSifre')"
                :error-messages="errors"
                filled
                autocomplete="new-password"
                rounded
                type="password"
                class="rounded"
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Yeni Şifre Tekrar"
              rules="confirmed:confirmation"
            >
              <v-text-field
                v-model="confirmPassword"
                :label="$t('yeniSifreTekrar')"
                :error-messages="errors"
                filled
                type="password"
                rounded
                class="rounded"
              ></v-text-field>
            </validation-provider>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading" plain @click="closeSubmit"> {{ $t('vazgec') }} </v-btn>
          <v-btn :loading="loading" depressed color="secondary" @click="submit"> {{ $t('degistir') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="d-block d-md-flex justify-center pt-2 pb-6 py-md-16">
        <v-card
          elevation="16"
          :rounded="$vuetify.breakpoint.mdAndUp ? 'xl' : 'lg'"
          :width="$vuetify.breakpoint.mdAndUp ? 750 : '100%'"
        >
          <v-card-title class="grey">
            <v-icon :size="$vuetify.breakpoint.mdAndUp ? '60' : '30'" left>
              {{ icons.mdiShieldAccount }}
            </v-icon>
            <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-body-1'">{{ $t('profil') }}</span>
            <v-spacer />
            <v-chip class="mr-3" small label> ID: {{ userData.uid }} </v-chip>
            <v-chip :color="userData.aktif === '1' ? 'success' : 'warning'" link outlined label small>
              {{ userData.aktif === '1' ? $t('aktif') : $t('pasif') }}
            </v-chip>
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-list color="transparent">
              <v-list-item two-line class="py-2">
                <v-list-item-content>
                  <v-list-item-subtitle> {{ $t('cariUnvan') }} </v-list-item-subtitle>
                  <v-list-item-title class="text-h6">
                    {{ userData.adsoyad }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-3"
                style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
              />
              <v-list-item two-line class="py-2">
                <v-list-item-content>
                  <v-list-item-subtitle> {{ $t('cariKod') }} </v-list-item-subtitle>
                  <v-list-item-title class="text-h6">
                    {{ userData.cari_kod }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-3"
                style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
              />
              <v-list-item two-line class="py-2">
                <v-list-item-avatar>
                  <v-avatar color="grey">
                    <v-icon size="24">
                      {{ icons.mdiAccountCircle }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle> {{ $t('kullaniciAdi') }} </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ userData.username }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-3"
                style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
              />
              <v-list-item two-line class="py-2">
                <v-list-item-avatar>
                  <v-avatar color="grey">
                    <v-icon size="24">
                      {{ icons.mdiLock }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle> {{ $t('sifre') }} </v-list-item-subtitle>
                  <v-list-item-title> ****** </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn color="nightDark" depressed class="text-capitalize" @click="dialog = true">
                    {{ $t('degistir') }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                class="my-3"
                style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
              />
              <v-list-item two-line class="py-2">
                <v-list-item-avatar>
                  <v-avatar color="grey">
                    <v-icon size="24">
                      {{ icons.mdiAt }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle class="mb-1">{{ $t('ePosta') }} </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ userData.mail }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-3"
                style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
              />
              <v-list-item two-line class="py-2">
                <v-list-item-avatar>
                  <v-avatar color="grey">
                    <v-icon size="24">
                      {{ icons.mdiPhone }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle class="mb-1"> {{ $t('telefon') }} </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ userData.gsm }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-3"
                style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
              />
              <v-list-item two-line class="py-2">
                <v-list-item-avatar>
                  <v-avatar color="grey">
                    <v-icon size="24">
                      {{ icons.mdiCalendar }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle class="mb-1"> {{ $t('kayitTarihi') }}</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ userData.kayit_tarihi }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-3"
                style="margin-left: -20px; max-width: calc(100% + 40px) !important; width: calc(100% + 40px) !important"
              />
              <v-list-item two-line class="py-2">
                <v-list-item-avatar>
                  <v-avatar color="grey">
                    <v-icon size="24">
                      {{ icons.mdiUpdate }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle class="mb-1"> {{ $t('sonGirisTarihi') }}</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ userData.son_giris }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import { mdiAccountCircle, mdiAt, mdiCalendar, mdiLock, mdiPhone, mdiShieldAccount, mdiUpdate } from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  setup() {
    const { uid } = JSON.parse(localStorage.getItem('userData'))

    const statusFind = (arr, value, select) => store?.state?.tanimlar[arr].find(item => item.value === value)[select]
    const { router } = useRouter()
    const dialog = ref(false)
    const userData = ref({})
    const loading = ref(false)
    const pageLoading = ref(true)
    const observer = ref(null)
    const oldPassword = ref(null)
    const newPassword = ref(null)
    const confirmPassword = ref(null)

    onMounted(() => {
      const method = 'getMemberDetail'
      let id
      if (router.currentRoute.params.id) {
        id = router.currentRoute.params.id
      } else {
        id = uid
      }

      store
        .dispatch('postMethod', { method, id })
        .then(response => {
          if (response.error === 0) {
            userData.value = response.detail.member
            pageLoading.value = false
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    })

    const closeSubmit = () => {
      dialog.value = false
      loading.value = false
      oldPassword.value = null
      newPassword.value = null
      confirmPassword.value = null
      observer.value.reset()
    }
    const submit = async () => {
      // loading.value = true
      // const isValid = await $refs.observer.validate()
      // if (!isValid) {
      // }

      const isValid = await observer.value.validate()
      if (isValid) {
        loading.value = true
        postData({
          method: 'memberPassUpdate',
          oldpass: oldPassword.value,
          newpass: newPassword.value,
        }).then(response => {
          if (response.error === 1) {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              icon: 'warning',
              background: '#FF4C51',
              confirmButtonColor: '#000',
            })
          } else if (response.error === 0) {
            closeSubmit()
            Vue.swal({
              title: 'Başarılı',
              text: response.msg,
              icon: 'success',
              background: '#56CA00',
              confirmButtonColor: '#000',
            })
          }
        })
      }
    }

    // ui

    return {
      statusFind,
      userData,
      loading,
      pageLoading,
      dialog,
      submit,
      oldPassword,
      newPassword,
      confirmPassword,
      observer,
      closeSubmit,
      icons: {
        mdiShieldAccount,
        mdiAccountCircle,
        mdiLock,
        mdiPhone,
        mdiAt,
        mdiCalendar,
        mdiUpdate,
      },
    }
  },
}
</script>
